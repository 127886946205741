import { default as indexxK5A3vrPQ6Meta } from "/app/pages/[continent-id]/[country-id]/[theme-slug]/index.vue?macro=true";
import { default as indexgxsy7HwYMqMeta } from "/app/pages/[continent-id]/[country-id]/activites/[activity-slug]/index.vue?macro=true";
import { default as indexDgUAnrMzTSMeta } from "/app/pages/[continent-id]/[country-id]/activites/index.vue?macro=true";
import { default as indexxQnUuk4WccMeta } from "/app/pages/[continent-id]/[country-id]/formulaire-de-devis/index.vue?macro=true";
import { default as indexu6mtSag4O4Meta } from "/app/pages/[continent-id]/[country-id]/guide/[guide-family]/[...guide-slug]/index.vue?macro=true";
import { default as indexmqhahqzSpHMeta } from "/app/pages/[continent-id]/[country-id]/guide/[guide-family]/index.vue?macro=true";
import { default as indexuT6o3Cm6vHMeta } from "/app/pages/[continent-id]/[country-id]/guide/index.vue?macro=true";
import { default as indexthTFcRpIkiMeta } from "/app/pages/[continent-id]/[country-id]/index.vue?macro=true";
import { default as indexa9GeIhKJtgMeta } from "/app/pages/[continent-id]/[country-id]/infos-pratiques/fiche-pays-[country]/index.vue?macro=true";
import { default as indexN0S2qt9tYeMeta } from "/app/pages/[continent-id]/[country-id]/infos-pratiques/formalites-et-visas-[preposition]-[country]/index.vue?macro=true";
import { default as index50Tw6d3YplMeta } from "/app/pages/[continent-id]/[country-id]/infos-pratiques/index.vue?macro=true";
import { default as indexqrS0aTldjGMeta } from "/app/pages/[continent-id]/[country-id]/infos-pratiques/ou-et-quand-partir-[preposition]-[country]/index.vue?macro=true";
import { default as indexNHaiFk9u1fMeta } from "/app/pages/[continent-id]/[country-id]/infos-pratiques/vols-internationaux-[preposition]-[country]/index.vue?macro=true";
import { default as indexwkDUFxSK3DMeta } from "/app/pages/[continent-id]/[country-id]/region/[slug]/index.vue?macro=true";
import { default as index8h8wDyfpeWMeta } from "/app/pages/[continent-id]/[country-id]/tours-b/[tour-id]/index.vue?macro=true";
import { default as indexRLY7bNHDg7Meta } from "/app/pages/[continent-id]/[country-id]/tours-b/index.vue?macro=true";
import { default as indexH1O8BpcrNvMeta } from "/app/pages/[continent-id]/[country-id]/tours/[tour-id]/index.vue?macro=true";
import { default as indexJGwkmogkDnMeta } from "/app/pages/[continent-id]/[country-id]/tours/index.vue?macro=true";
import { default as indexTmYBVlpvY5Meta } from "/app/pages/[continent-id]/[country-id]/villes/[city]/index.vue?macro=true";
import { default as indextSZEzSJ4e0Meta } from "/app/pages/[continent-id]/[country-id]/voyage-sur-mesure/index.vue?macro=true";
import { default as index9SLwhrmcqqMeta } from "/app/pages/[continent-id]/guide/[guide-family]/[...guide-slug]/index.vue?macro=true";
import { default as indexljXIoGTeqUMeta } from "/app/pages/[continent-id]/guide/[guide-family]/index.vue?macro=true";
import { default as indexSiZp6uMNxNMeta } from "/app/pages/[continent-id]/guide/index.vue?macro=true";
import { default as indexpCqUaGys6iMeta } from "/app/pages/[continent-id]/index.vue?macro=true";
import { default as indexfKnpGQcEdMMeta } from "/app/pages/agence-de-voyage-lyon/index.vue?macro=true";
import { default as indexy8MQlFOOLNMeta } from "/app/pages/blog-new/[article-id]/index.vue?macro=true";
import { default as indexmBgxeLTPk8Meta } from "/app/pages/blog-new/index.vue?macro=true";
import { default as indexkWnGO4pm5iMeta } from "/app/pages/charte-cookies/index.vue?macro=true";
import { default as indexauIW4MjXIYMeta } from "/app/pages/client/leclub/index.vue?macro=true";
import { default as indexRxwuRRgjpxMeta } from "/app/pages/client/parrainage/index.vue?macro=true";
import { default as index0kirR4bQSXMeta } from "/app/pages/conditions-de-vente[[date]]/index.vue?macro=true";
import { default as indexhixHU16ztyMeta } from "/app/pages/contactez-marco-vasco/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexOQ4iGs1RLqMeta } from "/app/pages/inspirations/[inspirations-slug]/guide/[guide-family]/[...guide-slug]/index.vue?macro=true";
import { default as index5lNzegN8jCMeta } from "/app/pages/inspirations/[inspirations-slug]/guide/[guide-family]/index.vue?macro=true";
import { default as indexgwo81w0kPeMeta } from "/app/pages/inspirations/[inspirations-slug]/guide/index.vue?macro=true";
import { default as indexgtpZUQ1vVXMeta } from "/app/pages/inspirations/[inspirations-slug]/index.vue?macro=true";
import { default as indexzjrYsarbd8Meta } from "/app/pages/inspirations/index.vue?macro=true";
import { default as indexbY2wGVNX3cMeta } from "/app/pages/marco-vasco-avis/index.vue?macro=true";
import { default as index39rP0fxt9VMeta } from "/app/pages/mentions-legales/index.vue?macro=true";
import { default as indexiDBYCUaO6oMeta } from "/app/pages/nos-conseillers/[consultant-id]/index.vue?macro=true";
import { default as indexNgaaPGvkHyMeta } from "/app/pages/nos-conseillers/index.vue?macro=true";
import { default as indexoDTna8YZajMeta } from "/app/pages/ou-partir-en-[month-id]/index.vue?macro=true";
import { default as indexS9S6VDlAyEMeta } from "/app/pages/ou-partir/index.vue?macro=true";
import { default as indexAVUZXwCFbOMeta } from "/app/pages/politique-de-confidentialite-marco-vasco/index.vue?macro=true";
import { default as indexSVaBYNq1b5Meta } from "/app/pages/qui-sommes-nous/index.vue?macro=true";
import { default as indexbIEtquLJ7tMeta } from "/app/pages/qui-sommes-nous/marco-vasco-recrute/index.vue?macro=true";
import { default as indexBV1zPX3VR7Meta } from "/app/pages/sitemap/index.vue?macro=true";
import { default as indexESSkXJY1ToMeta } from "/app/pages/tous-les-itineraires/index.vue?macro=true";
import { default as indexjXQa4XSjnAMeta } from "/app/pages/unsubscribe_marcovasco/index.vue?macro=true";
import { default as indexJ1YgiYODWMMeta } from "/app/pages/voyage-sur-mesure/index.vue?macro=true";
export default [
  {
    name: indexxK5A3vrPQ6Meta?.name ?? "continentid-countryid-themeslug",
    path: indexxK5A3vrPQ6Meta?.path ?? "/:continentid()/:countryid()/:themeslug()",
    meta: indexxK5A3vrPQ6Meta || {},
    alias: indexxK5A3vrPQ6Meta?.alias || [],
    redirect: indexxK5A3vrPQ6Meta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/[theme-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgxsy7HwYMqMeta?.name ?? "continentid-countryid-activites-activityslug",
    path: indexgxsy7HwYMqMeta?.path ?? "/:continentid()/:countryid()/activites/:activityslug()",
    meta: indexgxsy7HwYMqMeta || {},
    alias: indexgxsy7HwYMqMeta?.alias || [],
    redirect: indexgxsy7HwYMqMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/activites/[activity-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDgUAnrMzTSMeta?.name ?? "continentid-countryid-activites",
    path: indexDgUAnrMzTSMeta?.path ?? "/:continentid()/:countryid()/activites",
    meta: indexDgUAnrMzTSMeta || {},
    alias: indexDgUAnrMzTSMeta?.alias || [],
    redirect: indexDgUAnrMzTSMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/activites/index.vue").then(m => m.default || m)
  },
  {
    name: indexxQnUuk4WccMeta?.name ?? "continentid-countryid-formulaire-de-devis",
    path: indexxQnUuk4WccMeta?.path ?? "/:continentid()/:countryid()/formulaire-de-devis",
    meta: indexxQnUuk4WccMeta || {},
    alias: indexxQnUuk4WccMeta?.alias || [],
    redirect: indexxQnUuk4WccMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/formulaire-de-devis/index.vue").then(m => m.default || m)
  },
  {
    name: indexu6mtSag4O4Meta?.name ?? "continentid-countryid-guide-guidefamily-guideslug",
    path: indexu6mtSag4O4Meta?.path ?? "/:continentid()/:countryid()/guide/:guidefamily()/:guideslug(.*)*",
    meta: indexu6mtSag4O4Meta || {},
    alias: indexu6mtSag4O4Meta?.alias || [],
    redirect: indexu6mtSag4O4Meta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/guide/[guide-family]/[...guide-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqhahqzSpHMeta?.name ?? "continentid-countryid-guide-guidefamily",
    path: indexmqhahqzSpHMeta?.path ?? "/:continentid()/:countryid()/guide/:guidefamily()",
    meta: indexmqhahqzSpHMeta || {},
    alias: indexmqhahqzSpHMeta?.alias || [],
    redirect: indexmqhahqzSpHMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/guide/[guide-family]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuT6o3Cm6vHMeta?.name ?? "continentid-countryid-guide",
    path: indexuT6o3Cm6vHMeta?.path ?? "/:continentid()/:countryid()/guide",
    meta: indexuT6o3Cm6vHMeta || {},
    alias: indexuT6o3Cm6vHMeta?.alias || [],
    redirect: indexuT6o3Cm6vHMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexthTFcRpIkiMeta?.name ?? "continentid-countryid",
    path: indexthTFcRpIkiMeta?.path ?? "/:continentid()/:countryid()",
    meta: indexthTFcRpIkiMeta || {},
    alias: indexthTFcRpIkiMeta?.alias || [],
    redirect: indexthTFcRpIkiMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa9GeIhKJtgMeta?.name ?? "continentid-countryid-infos-pratiques-fiche-pays-country",
    path: indexa9GeIhKJtgMeta?.path ?? "/:continentid()/:countryid()/infos-pratiques/fiche-pays-:country()",
    meta: indexa9GeIhKJtgMeta || {},
    alias: indexa9GeIhKJtgMeta?.alias || [],
    redirect: indexa9GeIhKJtgMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/infos-pratiques/fiche-pays-[country]/index.vue").then(m => m.default || m)
  },
  {
    name: indexN0S2qt9tYeMeta?.name ?? "continentid-countryid-infos-pratiques-formalites-et-visas-preposition-country",
    path: indexN0S2qt9tYeMeta?.path ?? "/:continentid()/:countryid()/infos-pratiques/formalites-et-visas-:preposition()-:country()",
    meta: indexN0S2qt9tYeMeta || {},
    alias: indexN0S2qt9tYeMeta?.alias || [],
    redirect: indexN0S2qt9tYeMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/infos-pratiques/formalites-et-visas-[preposition]-[country]/index.vue").then(m => m.default || m)
  },
  {
    name: index50Tw6d3YplMeta?.name ?? "continentid-countryid-infos-pratiques",
    path: index50Tw6d3YplMeta?.path ?? "/:continentid()/:countryid()/infos-pratiques",
    meta: index50Tw6d3YplMeta || {},
    alias: index50Tw6d3YplMeta?.alias || [],
    redirect: index50Tw6d3YplMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/infos-pratiques/index.vue").then(m => m.default || m)
  },
  {
    name: indexqrS0aTldjGMeta?.name ?? "continentid-countryid-infos-pratiques-ou-et-quand-partir-preposition-country",
    path: indexqrS0aTldjGMeta?.path ?? "/:continentid()/:countryid()/infos-pratiques/ou-et-quand-partir-:preposition()-:country()",
    meta: indexqrS0aTldjGMeta || {},
    alias: indexqrS0aTldjGMeta?.alias || [],
    redirect: indexqrS0aTldjGMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/infos-pratiques/ou-et-quand-partir-[preposition]-[country]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHaiFk9u1fMeta?.name ?? "continentid-countryid-infos-pratiques-vols-internationaux-preposition-country",
    path: indexNHaiFk9u1fMeta?.path ?? "/:continentid()/:countryid()/infos-pratiques/vols-internationaux-:preposition()-:country()",
    meta: indexNHaiFk9u1fMeta || {},
    alias: indexNHaiFk9u1fMeta?.alias || [],
    redirect: indexNHaiFk9u1fMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/infos-pratiques/vols-internationaux-[preposition]-[country]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwkDUFxSK3DMeta?.name ?? "continentid-countryid-region-slug",
    path: indexwkDUFxSK3DMeta?.path ?? "/:continentid()/:countryid()/region/:slug()",
    meta: indexwkDUFxSK3DMeta || {},
    alias: indexwkDUFxSK3DMeta?.alias || [],
    redirect: indexwkDUFxSK3DMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/region/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index8h8wDyfpeWMeta?.name ?? "continentid-countryid-tours-b-tourid",
    path: index8h8wDyfpeWMeta?.path ?? "/:continentid()/:countryid()/tours-b/:tourid()",
    meta: index8h8wDyfpeWMeta || {},
    alias: index8h8wDyfpeWMeta?.alias || [],
    redirect: index8h8wDyfpeWMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/tours-b/[tour-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRLY7bNHDg7Meta?.name ?? "continentid-countryid-tours-b",
    path: indexRLY7bNHDg7Meta?.path ?? "/:continentid()/:countryid()/tours-b",
    meta: indexRLY7bNHDg7Meta || {},
    alias: indexRLY7bNHDg7Meta?.alias || [],
    redirect: indexRLY7bNHDg7Meta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/tours-b/index.vue").then(m => m.default || m)
  },
  {
    name: indexH1O8BpcrNvMeta?.name ?? "continentid-countryid-tours-tourid",
    path: indexH1O8BpcrNvMeta?.path ?? "/:continentid()/:countryid()/tours/:tourid()",
    meta: indexH1O8BpcrNvMeta || {},
    alias: indexH1O8BpcrNvMeta?.alias || [],
    redirect: indexH1O8BpcrNvMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/tours/[tour-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJGwkmogkDnMeta?.name ?? "continentid-countryid-tours",
    path: indexJGwkmogkDnMeta?.path ?? "/:continentid()/:countryid()/tours",
    meta: indexJGwkmogkDnMeta || {},
    alias: indexJGwkmogkDnMeta?.alias || [],
    redirect: indexJGwkmogkDnMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexTmYBVlpvY5Meta?.name ?? "continentid-countryid-villes-city",
    path: indexTmYBVlpvY5Meta?.path ?? "/:continentid()/:countryid()/villes/:city()",
    meta: indexTmYBVlpvY5Meta || {},
    alias: indexTmYBVlpvY5Meta?.alias || [],
    redirect: indexTmYBVlpvY5Meta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/villes/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: indextSZEzSJ4e0Meta?.name ?? "continentid-countryid-voyage-sur-mesure",
    path: indextSZEzSJ4e0Meta?.path ?? "/:continentid()/:countryid()/voyage-sur-mesure",
    meta: indextSZEzSJ4e0Meta || {},
    alias: indextSZEzSJ4e0Meta?.alias || [],
    redirect: indextSZEzSJ4e0Meta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/[country-id]/voyage-sur-mesure/index.vue").then(m => m.default || m)
  },
  {
    name: index9SLwhrmcqqMeta?.name ?? "continentid-guide-guidefamily-guideslug",
    path: index9SLwhrmcqqMeta?.path ?? "/:continentid()/guide/:guidefamily()/:guideslug(.*)*",
    meta: index9SLwhrmcqqMeta || {},
    alias: index9SLwhrmcqqMeta?.alias || [],
    redirect: index9SLwhrmcqqMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/guide/[guide-family]/[...guide-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexljXIoGTeqUMeta?.name ?? "continentid-guide-guidefamily",
    path: indexljXIoGTeqUMeta?.path ?? "/:continentid()/guide/:guidefamily()",
    meta: indexljXIoGTeqUMeta || {},
    alias: indexljXIoGTeqUMeta?.alias || [],
    redirect: indexljXIoGTeqUMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/guide/[guide-family]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSiZp6uMNxNMeta?.name ?? "continentid-guide",
    path: indexSiZp6uMNxNMeta?.path ?? "/:continentid()/guide",
    meta: indexSiZp6uMNxNMeta || {},
    alias: indexSiZp6uMNxNMeta?.alias || [],
    redirect: indexSiZp6uMNxNMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexpCqUaGys6iMeta?.name ?? "continentid",
    path: indexpCqUaGys6iMeta?.path ?? "/:continentid()",
    meta: indexpCqUaGys6iMeta || {},
    alias: indexpCqUaGys6iMeta?.alias || [],
    redirect: indexpCqUaGys6iMeta?.redirect || undefined,
    component: () => import("/app/pages/[continent-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfKnpGQcEdMMeta?.name ?? "agence-de-voyage-lyon",
    path: indexfKnpGQcEdMMeta?.path ?? "/agence-de-voyage-lyon",
    meta: indexfKnpGQcEdMMeta || {},
    alias: indexfKnpGQcEdMMeta?.alias || [],
    redirect: indexfKnpGQcEdMMeta?.redirect || undefined,
    component: () => import("/app/pages/agence-de-voyage-lyon/index.vue").then(m => m.default || m)
  },
  {
    name: indexy8MQlFOOLNMeta?.name ?? "blog-new-articleid",
    path: indexy8MQlFOOLNMeta?.path ?? "/blog-new/:articleid()",
    meta: indexy8MQlFOOLNMeta || {},
    alias: indexy8MQlFOOLNMeta?.alias || [],
    redirect: indexy8MQlFOOLNMeta?.redirect || undefined,
    component: () => import("/app/pages/blog-new/[article-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBgxeLTPk8Meta?.name ?? "blog-new",
    path: indexmBgxeLTPk8Meta?.path ?? "/blog-new",
    meta: indexmBgxeLTPk8Meta || {},
    alias: indexmBgxeLTPk8Meta?.alias || [],
    redirect: indexmBgxeLTPk8Meta?.redirect || undefined,
    component: () => import("/app/pages/blog-new/index.vue").then(m => m.default || m)
  },
  {
    name: indexkWnGO4pm5iMeta?.name ?? "charte-cookies",
    path: indexkWnGO4pm5iMeta?.path ?? "/charte-cookies",
    meta: indexkWnGO4pm5iMeta || {},
    alias: indexkWnGO4pm5iMeta?.alias || [],
    redirect: indexkWnGO4pm5iMeta?.redirect || undefined,
    component: () => import("/app/pages/charte-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexauIW4MjXIYMeta?.name ?? "client-leclub",
    path: indexauIW4MjXIYMeta?.path ?? "/client/leclub",
    meta: indexauIW4MjXIYMeta || {},
    alias: indexauIW4MjXIYMeta?.alias || [],
    redirect: indexauIW4MjXIYMeta?.redirect || undefined,
    component: () => import("/app/pages/client/leclub/index.vue").then(m => m.default || m)
  },
  {
    name: indexRxwuRRgjpxMeta?.name ?? "client-parrainage",
    path: indexRxwuRRgjpxMeta?.path ?? "/client/parrainage",
    meta: indexRxwuRRgjpxMeta || {},
    alias: indexRxwuRRgjpxMeta?.alias || [],
    redirect: indexRxwuRRgjpxMeta?.redirect || undefined,
    component: () => import("/app/pages/client/parrainage/index.vue").then(m => m.default || m)
  },
  {
    name: index0kirR4bQSXMeta?.name ?? "conditions-de-ventedate",
    path: index0kirR4bQSXMeta?.path ?? "/conditions-de-vente:date?",
    meta: index0kirR4bQSXMeta || {},
    alias: index0kirR4bQSXMeta?.alias || [],
    redirect: index0kirR4bQSXMeta?.redirect || undefined,
    component: () => import("/app/pages/conditions-de-vente[[date]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhixHU16ztyMeta?.name ?? "contactez-marco-vasco",
    path: indexhixHU16ztyMeta?.path ?? "/contactez-marco-vasco",
    meta: indexhixHU16ztyMeta || {},
    alias: indexhixHU16ztyMeta?.alias || [],
    redirect: indexhixHU16ztyMeta?.redirect || undefined,
    component: () => import("/app/pages/contactez-marco-vasco/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOQ4iGs1RLqMeta?.name ?? "inspirations-inspirationsslug-guide-guidefamily-guideslug",
    path: indexOQ4iGs1RLqMeta?.path ?? "/inspirations/:inspirationsslug()/guide/:guidefamily()/:guideslug(.*)*",
    meta: indexOQ4iGs1RLqMeta || {},
    alias: indexOQ4iGs1RLqMeta?.alias || [],
    redirect: indexOQ4iGs1RLqMeta?.redirect || undefined,
    component: () => import("/app/pages/inspirations/[inspirations-slug]/guide/[guide-family]/[...guide-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index5lNzegN8jCMeta?.name ?? "inspirations-inspirationsslug-guide-guidefamily",
    path: index5lNzegN8jCMeta?.path ?? "/inspirations/:inspirationsslug()/guide/:guidefamily()",
    meta: index5lNzegN8jCMeta || {},
    alias: index5lNzegN8jCMeta?.alias || [],
    redirect: index5lNzegN8jCMeta?.redirect || undefined,
    component: () => import("/app/pages/inspirations/[inspirations-slug]/guide/[guide-family]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgwo81w0kPeMeta?.name ?? "inspirations-inspirationsslug-guide",
    path: indexgwo81w0kPeMeta?.path ?? "/inspirations/:inspirationsslug()/guide",
    meta: indexgwo81w0kPeMeta || {},
    alias: indexgwo81w0kPeMeta?.alias || [],
    redirect: indexgwo81w0kPeMeta?.redirect || undefined,
    component: () => import("/app/pages/inspirations/[inspirations-slug]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexgtpZUQ1vVXMeta?.name ?? "inspirations-inspirationsslug",
    path: indexgtpZUQ1vVXMeta?.path ?? "/inspirations/:inspirationsslug()",
    meta: indexgtpZUQ1vVXMeta || {},
    alias: indexgtpZUQ1vVXMeta?.alias || [],
    redirect: indexgtpZUQ1vVXMeta?.redirect || undefined,
    component: () => import("/app/pages/inspirations/[inspirations-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzjrYsarbd8Meta?.name ?? "inspirations",
    path: indexzjrYsarbd8Meta?.path ?? "/inspirations",
    meta: indexzjrYsarbd8Meta || {},
    alias: indexzjrYsarbd8Meta?.alias || [],
    redirect: indexzjrYsarbd8Meta?.redirect || undefined,
    component: () => import("/app/pages/inspirations/index.vue").then(m => m.default || m)
  },
  {
    name: indexbY2wGVNX3cMeta?.name ?? "marco-vasco-avis",
    path: indexbY2wGVNX3cMeta?.path ?? "/marco-vasco-avis",
    meta: indexbY2wGVNX3cMeta || {},
    alias: indexbY2wGVNX3cMeta?.alias || [],
    redirect: indexbY2wGVNX3cMeta?.redirect || undefined,
    component: () => import("/app/pages/marco-vasco-avis/index.vue").then(m => m.default || m)
  },
  {
    name: index39rP0fxt9VMeta?.name ?? "mentions-legales",
    path: index39rP0fxt9VMeta?.path ?? "/mentions-legales",
    meta: index39rP0fxt9VMeta || {},
    alias: index39rP0fxt9VMeta?.alias || [],
    redirect: index39rP0fxt9VMeta?.redirect || undefined,
    component: () => import("/app/pages/mentions-legales/index.vue").then(m => m.default || m)
  },
  {
    name: indexiDBYCUaO6oMeta?.name ?? "nos-conseillers-consultantid",
    path: indexiDBYCUaO6oMeta?.path ?? "/nos-conseillers/:consultantid()",
    meta: indexiDBYCUaO6oMeta || {},
    alias: indexiDBYCUaO6oMeta?.alias || [],
    redirect: indexiDBYCUaO6oMeta?.redirect || undefined,
    component: () => import("/app/pages/nos-conseillers/[consultant-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgaaPGvkHyMeta?.name ?? "nos-conseillers",
    path: indexNgaaPGvkHyMeta?.path ?? "/nos-conseillers",
    meta: indexNgaaPGvkHyMeta || {},
    alias: indexNgaaPGvkHyMeta?.alias || [],
    redirect: indexNgaaPGvkHyMeta?.redirect || undefined,
    component: () => import("/app/pages/nos-conseillers/index.vue").then(m => m.default || m)
  },
  {
    name: indexoDTna8YZajMeta?.name ?? "ou-partir-en-monthid",
    path: indexoDTna8YZajMeta?.path ?? "/ou-partir-en-:monthid()",
    meta: indexoDTna8YZajMeta || {},
    alias: indexoDTna8YZajMeta?.alias || [],
    redirect: indexoDTna8YZajMeta?.redirect || undefined,
    component: () => import("/app/pages/ou-partir-en-[month-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexS9S6VDlAyEMeta?.name ?? "ou-partir",
    path: indexS9S6VDlAyEMeta?.path ?? "/ou-partir",
    meta: indexS9S6VDlAyEMeta || {},
    alias: indexS9S6VDlAyEMeta?.alias || [],
    redirect: indexS9S6VDlAyEMeta?.redirect || undefined,
    component: () => import("/app/pages/ou-partir/index.vue").then(m => m.default || m)
  },
  {
    name: indexAVUZXwCFbOMeta?.name ?? "politique-de-confidentialite-marco-vasco",
    path: indexAVUZXwCFbOMeta?.path ?? "/politique-de-confidentialite-marco-vasco",
    meta: indexAVUZXwCFbOMeta || {},
    alias: indexAVUZXwCFbOMeta?.alias || [],
    redirect: indexAVUZXwCFbOMeta?.redirect || undefined,
    component: () => import("/app/pages/politique-de-confidentialite-marco-vasco/index.vue").then(m => m.default || m)
  },
  {
    name: indexSVaBYNq1b5Meta?.name ?? "qui-sommes-nous",
    path: indexSVaBYNq1b5Meta?.path ?? "/qui-sommes-nous",
    meta: indexSVaBYNq1b5Meta || {},
    alias: indexSVaBYNq1b5Meta?.alias || [],
    redirect: indexSVaBYNq1b5Meta?.redirect || undefined,
    component: () => import("/app/pages/qui-sommes-nous/index.vue").then(m => m.default || m)
  },
  {
    name: indexbIEtquLJ7tMeta?.name ?? "qui-sommes-nous-marco-vasco-recrute",
    path: indexbIEtquLJ7tMeta?.path ?? "/qui-sommes-nous/marco-vasco-recrute",
    meta: indexbIEtquLJ7tMeta || {},
    alias: indexbIEtquLJ7tMeta?.alias || [],
    redirect: indexbIEtquLJ7tMeta?.redirect || undefined,
    component: () => import("/app/pages/qui-sommes-nous/marco-vasco-recrute/index.vue").then(m => m.default || m)
  },
  {
    name: indexBV1zPX3VR7Meta?.name ?? "sitemap",
    path: indexBV1zPX3VR7Meta?.path ?? "/sitemap",
    meta: indexBV1zPX3VR7Meta || {},
    alias: indexBV1zPX3VR7Meta?.alias || [],
    redirect: indexBV1zPX3VR7Meta?.redirect || undefined,
    component: () => import("/app/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexESSkXJY1ToMeta?.name ?? "tous-les-itineraires",
    path: indexESSkXJY1ToMeta?.path ?? "/tous-les-itineraires",
    meta: indexESSkXJY1ToMeta || {},
    alias: indexESSkXJY1ToMeta?.alias || [],
    redirect: indexESSkXJY1ToMeta?.redirect || undefined,
    component: () => import("/app/pages/tous-les-itineraires/index.vue").then(m => m.default || m)
  },
  {
    name: indexjXQa4XSjnAMeta?.name ?? "unsubscribe_marcovasco",
    path: indexjXQa4XSjnAMeta?.path ?? "/unsubscribe_marcovasco",
    meta: indexjXQa4XSjnAMeta || {},
    alias: indexjXQa4XSjnAMeta?.alias || [],
    redirect: indexjXQa4XSjnAMeta?.redirect || undefined,
    component: () => import("/app/pages/unsubscribe_marcovasco/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ1YgiYODWMMeta?.name ?? "voyage-sur-mesure",
    path: indexJ1YgiYODWMMeta?.path ?? "/voyage-sur-mesure",
    meta: indexJ1YgiYODWMMeta || {},
    alias: indexJ1YgiYODWMMeta?.alias || [],
    redirect: indexJ1YgiYODWMMeta?.redirect || undefined,
    component: () => import("/app/pages/voyage-sur-mesure/index.vue").then(m => m.default || m)
  }
]