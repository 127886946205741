import { MvTheme } from '@/lib/strapi-types/MvTheme';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

export async function useFindThemes(filters?: Record<string, unknown>) {
  const parsedQuery = stringify({
    populate: ['icon', 'landscape', 'squared'],
    filters,
    pagination: {
      start: 0,
      limit: 40,
      withCount: false
    }
  });

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvTheme['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.THEMES)}?${parsedQuery}`
  );

  const themes: MvTheme[] = data.value?.data ?? [];

  return {
    themes,
    primaryThemes: themes.filter(theme => theme.attributes.primary),
    secondaryThemes: themes.filter(theme => !theme.attributes.primary)
  };
}
